import Button from './Button';
import Image from './Image';
import { IconNotiText, IconNotiTitle } from './Text/TextIconNoti';
import Letter from '@/assets/img/tmp/letter.png';
import { breakpoint } from '@/helpers/BreakpointHelper';
import React, { FC } from 'react';
import styled from 'styled-components';

const LetterTooltip = styled.div`
  width: 100%;
  width: 360px;
  position: absolute;
  top: 30px;
  right: -50px;
  z-index: 1;

  ${IconNotiText} {
    border: 1px solid #979797;
    border-top: 0;
    background: #f8f9fa;
    padding: 20px;

    ${IconNotiTitle} {
      letter-spacing: -0.8px;
    }

    button {
      background-color: #fff;
      margin-top: 20px;
    }
  }

  ${breakpoint(`mobile`)} {
    width: 320px;
    right: -20px;
  }
`;

const TooltipOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
`;

interface PostTooltipProps {
  onRequestClose: (isDigitalSelected: boolean) => void;
}

const PostTooltip: FC<PostTooltipProps> = ({ onRequestClose }) => (
  <>
    <TooltipOverlay onClick={() => onRequestClose(false)} />
    <LetterTooltip>
      <div className="img-wrap">
        <Image pcSrc={Letter} mobileSrc={Letter} />
      </div>
      <IconNotiText>
        <b>
          온라인(이메일 또는 모바일)으로 유니세프 소식을 받아보시면 최대 연 4억
          원 이상의 경비를 절감해 더 많은 어린이를 구하고, 종이 절약으로 지구
          살리기에 기여하실 수 있습니다.
        </b>
        <Button outline full size="sm" onClick={() => onRequestClose(true)}>
          온라인으로 수신
        </Button>
      </IconNotiText>
    </LetterTooltip>
  </>
);

export default PostTooltip;
