import { SUPPORT_PHRASE_DATA } from '@/data/PhraseItemData';
import { hasJong } from '@/helpers/HangulHelper';
import React, { FC } from 'react';

export const PHRASE_ITEMS = [
  ...SUPPORT_PHRASE_DATA.education.items,
  ...SUPPORT_PHRASE_DATA.nutrition.items,
  ...SUPPORT_PHRASE_DATA.vaccination.items,
  ...SUPPORT_PHRASE_DATA.health.items,
  ...SUPPORT_PHRASE_DATA.water.items,
];

const SupportPhrase: FC<{ supportAmount: number }> = React.memo(
  ({ supportAmount }) => {
    const index = Math.floor(Math.random() * (PHRASE_ITEMS.length - 1));
    const item = PHRASE_ITEMS[index];
    // 연간 구입가능 갯수 카운팅
    const count = Math.round((supportAmount * 12) / item.price);
    return (
      <>
        월 <strong>{supportAmount.toLocaleString()}원</strong>이면,{` `}전 세계
        어린이를 위해{` `}
        <strong>
          {item.name} {count.toLocaleString()}
          {item.unit}
        </strong>
        {hasJong(item.unit) ? `을` : `를`}
        {` `}
        지원할 수 있습니다.
      </>
    );
  },
);

export default SupportPhrase;
